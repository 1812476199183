import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';

import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M9.29575 21.6249L18.0942 12.5002L9.29575 3.37549L8 4.6249L15.5937 12.5002L8 20.3755L9.29575 21.6249Z'
      fill='currentColor'
    />
  ),
});

const IconChevronRight: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconChevronRight);
