import {
  FetchQueryOptions,
  InfiniteData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';

import { LIMIT, REVALIDATE } from '@/entities/constants';
import { GetProducts200 } from '@/shared/api/codegen/demuzo';
import { productApi } from '@/shared/api/config';

import { mapApiProductToProduct } from '../lib';
import { productsKeys } from '../query-keys';

type Props = {
  limit: number;
  offset: number;
  initData?: GetProducts200;
};

type TInfinityProps = {
  initData?: InfiniteData<GetProducts200, number>;
};

export const getProductsAllPrefetchConfig = () =>
  ({
    queryKey: [...productsKeys.getProductsAll({ limit: 10, offset: 0 })],
    queryFn: async () => {
      const response = await productApi.getProductsV1All(
        { limit: 10, offset: 0 },
        { next: { revalidate: REVALIDATE } },
      );

      return response.items.map(mapApiProductToProduct);
    },
  }) satisfies FetchQueryOptions;

export const useGetProductsAll = ({ limit, offset, initData }: Props) => {
  return useQuery({
    queryKey: [...productsKeys.getProductsAll({ limit, offset })],
    queryFn: async () => {
      const response = await productApi.getProductsV1All({ offset, limit });
      return response.items.map(mapApiProductToProduct);
    },
    initialData: initData?.items.map(mapApiProductToProduct),
    refetchOnMount: !initData,
  });
};
export const useGetProductsAllInfinity = ({ initData }: TInfinityProps) => {
  return useInfiniteQuery({
    getNextPageParam: (lastPage: GetProducts200) => {
      if (lastPage.total > lastPage.limit + lastPage.offset) {
        return lastPage.offset / lastPage.limit + 1;
      }
      return undefined;
    },
    queryKey: productsKeys.getProductsAllInfinity(),
    initialPageParam: 0,
    queryFn: ({ pageParam }) =>
      productApi.getProductsV1All({ limit: LIMIT, offset: pageParam * 10 }),
    initialData: initData,
    refetchOnMount: !initData,
  });
};
