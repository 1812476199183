'use client';

import { Box, Flex } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { MouseEvent, useState } from 'react';
import { Swiper } from 'swiper/types';

import { useGetFeatured } from '@/entities/product';
import { mapApiProductToProduct } from '@/entities/product/model/lib';
import { useFavorites } from '@/features/favorites';
import { GetFeatured200 } from '@/shared/api/codegen/demuzo';
import { DesktopHomeFeaturedList } from '@/shared/components/desktop-home-featured-list/desktop-home-featured-list';
import { MobileHomeFeaturedList } from '@/shared/components/mobile-home-featured-list';
import PageLoader from '@/shared/components/page-loader/page-loader';
import IconButton from '@ui-kit/atoms/icon-button/icon-button';
import { useIsMobile } from '@ui-kit/hooks/useIsMobile';
import IconChevronLeft from '@ui-kit/icons/icon-chevron-left';
import IconChevronRight from '@ui-kit/icons/icon-chevron-right';
import Section from '@ui-kit/molecules/section/section';

type TProps = {
  initData?: GetFeatured200;
};

export const FeaturedWidget = ({ initData }: TProps) => {
  const t = useTranslations();
  const isMobile = useIsMobile();

  const { data, isLoading } = useGetFeatured({});

  const featuredData = data.items
    ? data
    : {
        ...initData,
        items: initData?.items.map(mapApiProductToProduct),
      };

  const [controlledSwiperFeatured, setControlledSwiperFeatured] =
    useState<Swiper | null>(null);

  const { handleToggle, favorites } = useFavorites();

  const handleToggleFavoriteFeature = ({
    evt,
    productId,
  }: {
    evt: MouseEvent<HTMLButtonElement>;
    productId: string;
  }) => {
    evt.preventDefault();
    evt.stopPropagation();

    handleToggle(productId);
  };
  return (
    <Section
      header={t('FeaturedWidget.title')}
      withPadding={isMobile}
      pHeader={{ base: '20px', lg: 0 }}
      rightAction={
        isMobile ? undefined : (
          <Flex gap='20px'>
            <IconButton
              aria-label='back'
              variant='transparent'
              icon={<IconChevronLeft />}
              onClick={() => controlledSwiperFeatured?.slidePrev()}
            />
            <IconButton
              aria-label='next'
              variant='transparent'
              icon={<IconChevronRight />}
              onClick={() => controlledSwiperFeatured?.slideNext()}
            />
          </Flex>
        )
      }
    >
      {(isLoading || !initData?.items.at(0)) && (
        <Box
          position='relative'
          w='full'
          h='200px'
        >
          <PageLoader />
        </Box>
      )}
      {isMobile ? (
        <MobileHomeFeaturedList
          favoritesProductId={favorites}
          products={featuredData?.items || []}
          onToggleFavoriteFeature={handleToggleFavoriteFeature}
        />
      ) : (
        <DesktopHomeFeaturedList
          favoritesProductId={favorites}
          products={featuredData?.items || []}
          onToggleFavoriteFeature={handleToggleFavoriteFeature}
          controlledSwiperFeatured={controlledSwiperFeatured || undefined}
          setControlledSwiperFeatured={setControlledSwiperFeatured}
        />
      )}
    </Section>
  );
};

export default FeaturedWidget;
