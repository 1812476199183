import { queryOptions, useQuery } from '@tanstack/react-query';

import { REVALIDATE } from '@/entities/constants';
import { GetFeatured200 } from '@/shared/api/codegen/demuzo';
import { productApi } from '@/shared/api/config';

import { mapApiProductToProduct } from '../lib';
import { productsKeys } from '../query-keys';

export const getProductV1FeaturedConfig = queryOptions({
  queryKey: [...productsKeys.getFeatured()],
  queryFn: async () => {
    const response = await productApi.getProductV1Featured({
      next: { revalidate: REVALIDATE },
    });

    const mappedProducts = response.items.map(mapApiProductToProduct);

    return {
      ...response,
      items: mappedProducts,
    };
  },
});

type TArgs = {
  initData?: GetFeatured200;
};

export const useGetFeatured = ({ initData }: TArgs) => {
  return useQuery({
    queryKey: [...productsKeys.getFeatured()],
    queryFn: async () => {
      const response = await productApi.getProductV1Featured({
        next: { revalidate: REVALIDATE },
      });

      const mappedProducts = response.items.map(mapApiProductToProduct);

      return {
        ...response,
        items: mappedProducts,
      };
    },
    initialData: {
      ...initData,
      items: initData?.items.map(mapApiProductToProduct),
    },
    refetchOnMount: !initData,
  });
};
