import { Flex } from '@chakra-ui/react';
import { useRouter } from 'next/navigation';
import { signIn, useSession } from 'next-auth/react';
import { FC, MouseEvent, useCallback } from 'react';

import { usePlayerData } from '@/features/audio-player/hooks/use-player-data';
import { Snippet } from '@/features/audio-player/types';
import {
  CrowdFundingCampaignStatusEnum,
  Product,
} from '@/shared/api/codegen/demuzo';
import { Card } from '@/shared/ui-kit/organisms/card';
import {
  calculateDaysLeft,
  calculateProgressStartDay,
} from '@/shared/utils/calculate-days-left';

type Props = {
  products: (Omit<Product, 'snippets'> & { snippets: Snippet[] })[];
  favoritesProductId: string[];
  onToggleFavoriteFeature: ({
    evt,
    productId,
    isFavorite,
    isLogged,
  }: {
    evt: MouseEvent<HTMLButtonElement>;
    productId: string;
    isFavorite: boolean;
    isLogged: boolean;
  }) => void;
};

export const MobileHomeFeaturedList: FC<Props> = ({
  products,
  favoritesProductId,
  onToggleFavoriteFeature,
}) => {
  const session = useSession();
  const { updateData } = usePlayerData();
  const { push } = useRouter();

  const onBuyClick = useCallback(
    async (id: string) => {
      const callbackUrl = `/project/purchase/${id}`;
      if (session.status === 'unauthenticated') {
        await signIn('keycloak', { callbackUrl });
        return;
      }

      push(callbackUrl);
    },
    [session, push],
  );
  return (
    <Flex
      direction='column'
      gap='20px'
    >
      {products.map((product) => {
        const snippet = product.snippets.at(0);

        const handleCurrentPlay = () => {
          updateData({
            currentCampaignId: product.id,
            isFavoriteCampaign: product.isFavorite,
            canBuy: product.crowdFundingCampaign.status === 'in_progress',
          });
        };
        const genres = product.secondaryGenre
          ? `${product.primaryGenre} · ${product.secondaryGenre}`
          : product.primaryGenre;
        const isFavorite = favoritesProductId.includes(product.id);
        return (
          <Card
            href={`/project/${product.id}`}
            key={product.id}
            snippet={snippet}
            imageUrl={product.coverUrls.at(0) ?? ''}
            onPlay={handleCurrentPlay}
            onToggleFavoriteFeature={(evt) => {
              onToggleFavoriteFeature({
                evt,
                productId: product.id,
                isFavorite: isFavorite,
                isLogged: true,
              });
            }}
            canBuy={
              product.crowdFundingCampaign.status ===
              CrowdFundingCampaignStatusEnum.in_progress
            }
            onBuy={() => {
              onBuyClick(product.id);
            }}
            isFavorite={isFavorite}
            totalInvested={product.crowdFundingCampaign.totalInvested}
            daysLeft={calculateDaysLeft(
              product.crowdFundingCampaign.startDateTime,
              product.crowdFundingCampaign.endDateTime,
            )}
            limit={product.crowdFundingCampaign.successThreshold}
            progress={Number(product.crowdFundingCampaign.progress)}
            crowdFundingCampaignStatus={product.crowdFundingCampaign.status}
            progressToStart={
              product.crowdFundingCampaign.createdAt
                ? calculateProgressStartDay(
                    product.crowdFundingCampaign.createdAt,
                    product.crowdFundingCampaign.startDateTime,
                  )
                : 1
            }
            productName={product.name}
            artists={product.artist}
            genres={genres}
            sharePrice={product.crowdFundingCampaign.sharePrice}
            isFeatured
          />
        );
      })}
    </Flex>
  );
};
